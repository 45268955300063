import { format as formatFn } from "date-fns";
import { useCallback } from "react";
import { useUserContext } from "../context/UserContext";
import { TimeFormat, timeFormatMap, TimeFormatType } from "../utils/dates";

export type DateTimeFormatHandler = (date: Date, format: TimeFormat) => string;

export type UseDateTimeFormatUtilsReturnType = {
  format: DateTimeFormatHandler;
  shortenTime: (time: string) => string;
};

export const useDateTimeFormatUtils = (): UseDateTimeFormatUtilsReturnType => {
  const [{ user }] = useUserContext();

  const type: TimeFormatType = !!user?.settings?.format24HourTime ? "24h" : "12h";

  const format = useCallback(
    (date: Date, timeFormat: TimeFormat) => {
      const userTimeFormat = timeFormatMap[timeFormat]?.[type] || timeFormatMap[timeFormat]?.["12h"];

      if (!userTimeFormat) {
        throw new Error("Unknown formatting. Please provide a valid TimeFormat");
      }

      return formatFn(date, userTimeFormat);
    },
    [type]
  );

  const shortenTime = useCallback((time: string) => (type === "12h" ? time.replace(":00", "") : time), [type]);

  return { format, shortenTime };
};
